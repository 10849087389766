import CustomTable from "Components/CustomTable/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { RenderCreated, RenderUpdated, RenderActionButtons, getCommonColumns } from "Components/CustomTable/Commons";
import AddEditForm from "Components/Forms/Master/Settings/AddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";

const apiUrl = "/master/rest/settings";
const headerTitle = "Settings";
const editPermission = "settings:edit";
const deletePermission = "settings:delete";
const addPermission = "settings:add";

const columns = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Convenience Fee',
        dataIndex: 'convenience_fee',
        key: 'convenience_fee',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Convenience Fee Type',
        dataIndex: 'convenience_fee_type',
        key: 'convenience_fee_type',
        // render: (text) => <a>{text}</a>,
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
];

const Settings = () => {

    const userPermissions = useSelector(store => store.auth?.user?.permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default Settings;