import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as actions from "Redux/MasterReducer/crudSlices";
import { Button, Flex, Select } from "antd";


const ServicesDocumentTypePage = () => {

    const { serviceId } = useParams();
    const [docTypes, setDocTypes] = useState([]);
    const [currentDocTypes, setCurrentDocTypes] = useState([]);
    const [data, setData] = useState(null)

    let allOptions = docTypes.map((doc) => {
        return {
            value: doc.id,
            label: doc.title,
        }
    });

    let options = currentDocTypes.map((doc) => {
        return {
            value: doc.document_type,
            label: doc.document_type_title,
        }
    });

    const fetchAllDocumentTypes = async () => {
        const response = await actions.getDocumentTypes();
        if (response) {
            setDocTypes(response.results)
        }
    }

    const fetchServiceDocumentTypes = async () => {
        const response = await actions.getServiceDocumentTypes(serviceId);
        if (response) {
            setCurrentDocTypes(response.results)
        }
    }

    const handleChange = async (selectedValues) => {
        setData(selectedValues);
    }

    const submitData = async () => {
        const response = await actions.createServiceDocumentTypes({ "service": serviceId, "selected_values": data });
        if (response) {
            console.log(response);
        }
    }

    useEffect(() => {
        if (serviceId) {
            fetchServiceDocumentTypes();
        }
    }, [serviceId])

    useEffect(() => {
        fetchAllDocumentTypes();
    }, [])

    return (
        <div>
            <h3>Service Document Type</h3>
            <Flex vertical>
                {options.length > 0 && <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '50%', marginBottom: '10px' }}
                    placeholder="Please select"
                    defaultValue={options}
                    onChange={handleChange}
                    options={allOptions}
                />}
                {options.length === 0 && <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '50%', marginBottom: '10px' }}
                    placeholder="Please select"
                    onChange={handleChange}
                    options={allOptions}
                />}
                <Button onClick={submitData} style={{ width: 100 }}>Submit</Button>
            </Flex>
        </div>
    )
}

export default ServicesDocumentTypePage;