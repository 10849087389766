import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import AddEditForm from "Components/Forms/Order/Orders/AddEditForm";
import allowedFiltersEnum from "Utils/allowedFiltersEnum";
import getPermittedColumns from "Utils/getPermittedColumns";
import { Flex } from "antd";
import { useSelector } from "react-redux";

const apiUrl = "/order/rest/orders";
const headerTitle = "Orders";
const editPermission = "";
const deletePermission = "";
const addPermission = "";

const columns = [
    {
        title: 'Invoice No.',
        dataIndex: 'invoice_no',
        key: 'invoice_no',
    },
    {
        title: 'User',
        dataIndex: 'user',
        key: 'user',
    },
    {
        title: 'Theatre',
        dataIndex: 'theatre',
        key: 'theatre',
        render: (text, row) => (
            <Flex vertical>
                <span>{row?.theatre_data?.title}</span>
            </Flex>
        ),
    },
    {
        title: 'Show',
        dataIndex: 'show',
        key: 'show',
        render: (text, row) => (
            <Flex vertical>
                <span>{row?.show_data?.client_program_data?.title}</span>
                <span>{row?.show_data?.date} {row?.show_data?.start_time?.slice?.(0, 5)}</span>
            </Flex>
        ),
    },
    {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status'
    },
    // {
    //     title: 'Hall',
    //     dataIndex: 'hall',
    //     key: 'hall',
    //     render: (text, row) => (
    //         <Flex vertical>
    //             <span>{row?.show_data?.hall?.hall_no}</span>
    //         </Flex>
    //     ),
    // },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
];

const OrderPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
        filters={[allowedFiltersEnum.THEATRE.theatre, allowedFiltersEnum.CLIENT.theatre__client]}
    />;
}

export default OrderPage;