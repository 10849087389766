import { Flex } from "antd";
import React, {useEffect, useState} from "react";

const NoAccess = ({permissionNeeded='his:dashboard'}) => {
    
    switch(permissionNeeded){
        case "his:dashboard":
        default:
            return (
                <Flex vertical align="center" justify="center" style={{height: "100%"}}>
                    You do not have access for this page.
                </Flex>
            );
    }
}

export default NoAccess;
