import { Routes, Route, Outlet, Navigate } from "react-router-dom";

import DashboardLayout from "../Components/Layouts/Dashboard";

import BlogsPage from "Pages/Dashboard/Blog/BlogsPage";
import DocumentsPage from "Pages/Dashboard/Document/DocumentsPage";

import DocumentTypesPage from "Pages/Dashboard/Master/DocumentTypes/DocumentTypesPage";
import ServicesPage from "Pages/Dashboard/Master/Services/ServicesPage";
import ServicesDocumentTypePage from "Pages/Dashboard/Master/Services/ServiceDocumentTypePage";
import CountriesPage from "Pages/Dashboard/Master/Countries/CountriesPage";
import LanguagesPage from "Pages/Dashboard/Master/Languages/LanguagesPage";
import SettingsPage from "Pages/Dashboard/Master/Settings/SettingsPage";

import SessionPage from "Pages/Dashboard/Session/SessionPage";
import SessionDocumentPage from "Pages/Dashboard/Session/SessionDocumentPage";

import PageNotFound from "Pages/PageNotFound";
import UsersPage from "Pages/Dashboard/PlatformUsers/UsersPage";
import DashboardPage from "Pages/Dashboard/DashboardPage";
import RolesPage from "Pages/Dashboard/PlatformUsers/RolesPage";
import PermissionsPage from "Pages/Dashboard/PlatformUsers/PermissionsPage";
import { useSelector } from "react-redux";
import NoAccess from "./Access/NoAccess";
import AccessControl from "./Access/AccessControl";
import HomePage from "Pages/HomePage";

import FailurePage from "Pages/Payments/FailurePage";
import SuccessPage from "Pages/Payments/SuccessPage";
import OrderPage from "Pages/Dashboard/Orders/OrdersPage";
import PaymentsPage from "Pages/Dashboard/Orders/PaymentsPage";
import RefundsPage from "Pages/Dashboard/Orders/RefundsPage";


const DashboardRoutes = () => {
    const user = useSelector(store => store.auth.user);

    console.log(user);

    return (
        <DashboardLayout>
            <Routes>
                <Route path="" element={<HomePage />} />
                <Route path="blog" element={<Outlet />} >
                    <Route path="blogs" element={<AccessControl
                        userPermissions={user.permissions}
                        allowedPermissions={["blogs:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="blogs:view" />
                        )}
                    >
                        <BlogsPage />
                    </AccessControl>} />
                </Route>
                <Route path="document" element={<Outlet />} >
                    <Route path="documents" element={<AccessControl
                        userPermissions={user.permissions}
                        allowedPermissions={["documents:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="documents:view" />
                        )}
                    >
                        <DocumentsPage />
                    </AccessControl>} />
                </Route>
                <Route path="master" element={<Outlet />}>
                    <Route path="countries" element={<AccessControl
                        userPermissions={user.permissions}
                        allowedPermissions={["countries:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="countries:view" />
                        )}
                    >
                        <CountriesPage />
                    </AccessControl>} />
                    <Route path="languages" element={<AccessControl
                        userPermissions={user.permissions}
                        allowedPermissions={["languages:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="languages:view" />
                        )}
                    >
                        <LanguagesPage />
                    </AccessControl>} />
                    <Route path="document-types" element={<AccessControl
                        userPermissions={user.permissions}
                        allowedPermissions={["documenttypes:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="documenttypes:view" />
                        )}
                    >
                        <DocumentTypesPage />
                    </AccessControl>} />
                    <Route path="services" element={<AccessControl
                        userPermissions={user.permissions}
                        allowedPermissions={["services:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="services:view" />
                        )}
                    >
                        <ServicesPage />
                    </AccessControl>} >
                        <Route path=":serviceId" element={<ServicesDocumentTypePage />} />
                    </Route>
                    {/* 
                    <Route path="settings" element={<AccessControl
                        userPermissions={user.permissions}
                        allowedPermissions={["settings:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="settings:view" />
                        )}
                    >
                        <SettingsPage />
                    </AccessControl>} /> */}
                </Route>
                <Route path="session" element={<Outlet />}>
                    <Route path="sessions" element={<AccessControl
                        userPermissions={user.permissions}
                        allowedPermissions={["sessions:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="sessions:view" />
                        )}
                    >
                        <SessionPage />
                    </AccessControl>} >
                        <Route path=":sessionId" element={<SessionDocumentPage />} />
                    </Route>
                </Route>
                {/* <Route path="order" element={<Outlet />} >
                    <Route path="orders" element={<AccessControl
                        userPermissions={user.permissions}
                        allowedPermissions={["order:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="order:view" />
                        )}
                    >
                        <OrderPage />
                    </AccessControl>} />
                    <Route path="payments" element={<AccessControl
                        userPermissions={user.permissions}
                        allowedPermissions={["payments:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="payments:view" />
                        )}
                    >
                        <PaymentsPage />
                    </AccessControl>} />
                </Route> */}
                <Route path="platform-users" element={<Outlet />}>
                    <Route path="users"
                        element={<AccessControl
                            userPermissions={user.permissions}
                            allowedPermissions={["users:view"]}
                            renderNoAccess={() => (
                                <NoAccess permissionNeeded="users:view" />
                            )}
                        >
                            <UsersPage />
                        </AccessControl>}
                    />
                    <Route path="roles"
                        element={<AccessControl
                            userPermissions={user.permissions}
                            allowedPermissions={["roles:view"]}
                            renderNoAccess={() => (
                                <NoAccess permissionNeeded="roles:view" />
                            )}
                        >
                            <RolesPage />
                        </AccessControl>}
                    />
                    <Route path="permissions"
                        element={<AccessControl
                            userPermissions={user.permissions}
                            allowedPermissions={["permissions:view"]}
                            renderNoAccess={() => (
                                <NoAccess permissionNeeded="permissions:view" />
                            )}
                        >
                            <PermissionsPage />
                        </AccessControl>}
                    />
                </Route>
                <Route path="dashboard"
                    element={<AccessControl
                        userPermissions={user.permissions}
                        allowedPermissions={["dashboard:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="dashboard:view" />
                        )}
                    >
                        <DashboardPage />
                    </AccessControl>} />
                <Route path="payment/status" element={<Outlet />}>
                    <Route path="failure" element={<FailurePage />} />
                    <Route path="success" element={<SuccessPage />} />
                </Route>
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </DashboardLayout>
    );
}

export default DashboardRoutes;