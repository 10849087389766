import { formatBytes } from "Utils/formatBytes";
import sizeof from "object-sizeof";

export const storeSizeLoggerMiddleware = (store) => (next) => (action) => {
    // const previousState = store.getState();
    const result = next(action);
    const newState = store.getState();

    // let prevStateSizeInBytes = sizeof(previousState);
    let newStateSizeInBytes = sizeof(newState);

    // let diff = newStateSizeInBytes - prevStateSizeInBytes;

    // console.log({store_size: formatBytes(newStateSizeInBytes), action: action.type});

    return result;
};