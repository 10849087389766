const responseCodeMessages = {
    noSeatType: {
        code: "noSeatType",
        message: "Please create one seat type before creating a hall."
    },
    seatsPresent: {
        code: "seatPresent",
        message: "There are already seats present for this seat type, cannot delete."
    },
    seatReductionNotAllowed: {
        code: "seatReductionNotAllowed",
        message: "Seats can only be increased once set."
    },
    ERR_NETWORK: {
        code: "ERR_NETWORK",
        message: "Server not reachable or internet is not working.",
        title: "Network Error Occurred"
    },
    WIP: "This API is a work in progress.",
}

export default responseCodeMessages;