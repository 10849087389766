import React from 'react'

const FailurePage = () => {
    const containerStyle = {
        textAlign: 'center',
        border: '1px solid #ddd',
        padding: '40px',
        borderRadius: '8px',
        margin: '30px auto',
        maxWidth: '500px'
    };

    const headingStyle = {
        color: '#dc3545',
        marginBottom: '20px',
        fontSize: '24px'
    };

    const errorMessageStyle = {
        fontWeight: 'bold',
        marginBottom: '15px'
    };

    const buttonStyle = {
        backgroundColor: '#007bff',
        color: 'white',
        padding: '15px 30px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        transition: 'background-color 0.3s ease'
    };

    const buttonHoverStyle = {
        backgroundColor: '#0056b3' // Darker blue on hover
    };

    return (
        <div style={containerStyle}>
            <h1 style={headingStyle}>Transaction Failed</h1>
            <p style={errorMessageStyle}>
                We're sorry, your transaction could not be processed at this time.
            </p>
            <p>
                Please check your payment details and try again, or contact us for support.
            </p>
        </div>
    );
}

export default FailurePage