import { FormModesEnum } from "Redux/enums";
import { useSelector } from "react-redux";

const AddEditFormComponent = ({ changeFormMode, apiUrl, ViewComponent, MyFormComponent }) => {

    const currentFormMode = useSelector(store => store.master?.currentFormMode);

    if ((currentFormMode === FormModesEnum.VIEW && ViewComponent)) {
        return <ViewComponent
            changeFormMode={changeFormMode}
            apiUrl={apiUrl}
        />
    }

    return <MyFormComponent
        changeFormMode={changeFormMode}
        apiUrl={apiUrl}
    />

}

export default AddEditFormComponent;