import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import AddEditForm from "Components/Forms/Order/Orders/AddEditForm";
import allowedFiltersEnum from "Utils/allowedFiltersEnum";
import getPermittedColumns from "Utils/getPermittedColumns";
import { Flex } from "antd";
import { useSelector } from "react-redux";

const apiUrl = "/order/rest/payments";
const headerTitle = "Payments";
const editPermission = "";
const deletePermission = "";
const addPermission = "";

const columns = [
    {
        title: 'Order',
        dataIndex: 'order',
        key: 'order',
    },
    {
        title: 'Food Order',
        dataIndex: 'food_order',
        key: 'food_order'
    },
    {
        title: 'Payment Method',
        dataIndex: 'payment_method',
        key: 'payment_method',
    },
    {
        title: 'Payment Status',
        dataIndex: 'payment_status',
        key: 'payment_status',
    },
    {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
];

const OrderPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
        filters={[allowedFiltersEnum.THEATRE.order__theatre, allowedFiltersEnum.CLIENT.order__theatre__client]}
    />;
}

export default OrderPage;