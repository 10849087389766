import React, { useState } from 'react';
import { Layout, theme } from 'antd';
import SideBar from './SideBar';
import TopNavigation from './TopNavigation';
import CustomBreadCrumb from './CustomBreadCrumb';

const { Content } = Layout;

const DashboardLayout = ({ children }) => {
    const [collapsed, setCollapsed] = useState(false);

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    return (
        <Layout style={{ height: "100svh" }}>
            <SideBar trigger={null} collapsible collapsed={collapsed} />
            <Layout>
                <TopNavigation collapsible collapsed={collapsed} setCollapsed={setCollapsed} />
                {/* <Breadcrumb
                    style={{
                        margin: '24px 0px 0px 16px',
                    }}
                >
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>List</Breadcrumb.Item>
                    <Breadcrumb.Item>App</Breadcrumb.Item>
                </Breadcrumb> */}
                <Content
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                        background: colorBgContainer,
                        borderRadius: borderRadiusLG,
                    }}
                >
                    <CustomBreadCrumb />
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
};
export default DashboardLayout;