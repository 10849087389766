import { Input } from "antd";
import { useSearchParams } from "react-router-dom";
import searchParamsEnum from 'Utils/searchParamsEnum';

const Search = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    

    const onSearch = (e) => {
        // console.log({ e });
        if (e) {
            setSearchParams(params => {
                params.set(searchParamsEnum.PAGE, 1);
                params.set(searchParamsEnum.SEARCH, e);
                return params;
            });
        } else {
            if (searchParams.get(searchParamsEnum.SEARCH)) {
                setSearchParams(params => {
                    params.delete(searchParamsEnum.SEARCH);
                    return params;
                })
            }
        }
    }

    return <>
        <Input.Search 
            placeholder="Search" 
            allowClear 
            onSearch={onSearch} 
            defaultValue={searchParams.get("search")} 
            enterButton 
            style={{ maxWidth: 285 }} 
        />
    </>;
}

export default Search;