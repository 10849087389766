import axios from "axios";
import AuthRoutes from "./AuthRoutes";
import DashboardRoutes from "./DashboardRoutes";
import { useSelector } from "react-redux";

const AllRoutes = () => {

    const token = useSelector(store => store.auth.token);
    const isAuthenticated = useSelector(store => store.auth.isAuthenticated);

    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

    if (isAuthenticated){
        axios.defaults.headers.common.Authorization = `Token ${token}`;
    }

    if (isAuthenticated){
        return <DashboardRoutes />;
    } else {
        return <AuthRoutes />;
    }
}

export default AllRoutes;