import React, { useEffect, useState } from 'react';
import { Button, Flex, Form, Input, Select, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons"
import { fetchAllPermissionsList } from 'Api/commonApis';

const AddEditForm = ({ apiUrl }) => {
    const [notificationApi, contextHolder] = notification.useNotification();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [availablePermissions, setAvailablePermissions] = useState([]);
    const [availablePermissionsLoading, setAvailablePermissionsLoading] = useState(null);

    const openNotification = (message, description, type) => {
        notificationApi[type]({
            message: message,
            description: description,
            placement: "top"
        });
    };

    const currentFormMode = useSelector(store => store.master?.currentFormMode);
    const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
    const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
    const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
    const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
    const currentRecordData = useSelector(store => store.master?.currentRecordData);

    useEffect(() => {
        const fetchAllPermissionsOptions = async () => {
            setAvailablePermissionsLoading(true);
            try {
                const list = await fetchAllPermissionsList();
                if (Array.isArray(list)) {
                    const options = list.map(l => ({ ...l, value: l.id, label: l.permission_name }))
                    setAvailablePermissions(options);
                    let sp = options.filter(r => {
                        console.log({ currentRecordData, r })
                        if (currentRecordData.permissions && (r.id in currentRecordData.permissions)) {
                            return r;
                        } else {
                            return null;
                        }
                    });
                    setSelectedPermissions(sp);
                } else {
                    throw new Error("Fetch AllPermissions did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("AllPermissions Options", err.message, "error");
                } else {
                    openNotification("AllPermissions Options", "Something went wrong while fetching user options", "error")
                }
            }
            setAvailablePermissionsLoading(false);
        }
        if (currentFormMode === FormModesEnum.ADD) {
            fetchAllPermissionsOptions();
        } else {
            if (currentFormMode === FormModesEnum.EDIT && currentRecordData?.permissions) {
                fetchAllPermissionsOptions();
            }
        }
    }, [currentEditViewFormId, currentFormMode, currentRecordData]);

    useEffect(() => {
        if (editRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
        } else if (addRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
        } else if (currentFormMode === FormModesEnum.ADD) {
            form.resetFields();
        }
    }, [editRecordStatus, addRecordStatus, form, currentFormMode]);

    useEffect(() => {
        // console.log({ fetchARecord, currentRecordData });
        form.setFieldsValue(currentRecordData);
    }, [fetchRecordStatus, currentRecordData]);

    useEffect(() => {
        // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
        if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
            if (!currentEditViewFormId) {
                openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
                return;
            } else {
                dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
            }
        }
    }, [currentEditViewFormId, currentFormMode, dispatch]);

    const addNew = (val) => {
        // console.log(val);
        dispatch(addARecord({ apiUrl: apiUrl, data: val }));
    }

    const edit = (val) => {
        // console.log(val);
        dispatch(editARecord({ apiUrl, id: currentEditViewFormId, data: val }));
    }

    const onFinish = (val) => {
        delete val.confirm_password;
        val.username = val.email;
        if (currentFormMode === FormModesEnum.ADD) {
            addNew(val);
        } else if (currentFormMode === FormModesEnum.EDIT) {
            edit(val);
        } else {
            console.log("No suitable mode found");
        }
    }

    const onSelectPermissionChange = function (value, option) {
        console.log({ value, option });
        setSelectedPermissions(prev => {
            let r = JSON.parse(JSON.stringify(prev))
            if (Array.isArray(r)) {
                r.push(option);
            } else {
                r = []
                r.push(option);
            }
            return r;
        })
    }

    const onDeselectPermissionChange = function (value, option) {
        console.log({ value, option });
        let ss = JSON.parse(JSON.stringify(selectedPermissions));
        if (Array.isArray(ss)) {
            // 
        } else {
            ss = [];
        }
        ss = ss.filter(r => r.id !== value);
        setSelectedPermissions(ss);
    }

    return (
        <>
            {contextHolder}
            <Form layout="vertical"
                requiredMark={true}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
                disabled={currentFormMode === FormModesEnum.VIEW}
            >
                <Form.Item
                    name="role_name"
                    label="Name"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter name',
                        },
                    ]}
                >
                    <Input placeholder="Please enter name" />
                </Form.Item>
                <Form.Item
                    name="role_code"
                    label="Role Code"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter Role Code',
                        },
                        {
                            max: 50,
                            message: "Code cannot be more than 50 characters long."
                        }
                    ]}
                >
                    <Input showCount placeholder="Please enter Role Code" />
                </Form.Item>
                <Form.Item
                    label="Permissions"
                    name="permissions"
                    rules={[
                        {
                            required: true,
                            message: "Permissions are required"
                        }
                    ]}
                >
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '300px' }}
                        loading={availablePermissionsLoading}
                        value={selectedPermissions?.map?.(s => {
                            s["key"] = s.id;
                            s["label"] = s.role_name;
                            s["value"] = s.id;
                            return s;
                        })}
                        options={availablePermissions}
                        onSelect={onSelectPermissionChange}
                        onDeselect={onDeselectPermissionChange}
                    />
                </Form.Item>
                {
                    currentFormMode === FormModesEnum.ADD ? <Form.Item>
                        {
                            addRecordStatus === asyncStatuses.LOADING ?
                                <Button type="primary" htmlType="submit" disabled>
                                    <LoadingOutlined />
                                    Submitting
                                </Button> :
                                <Button type="primary" htmlType="submit" >
                                    Submit
                                </Button>
                        }
                    </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
                        {
                            editRecordStatus === asyncStatuses.LOADING ?
                                <Button type="primary" htmlType="submit" disabled>
                                    <LoadingOutlined />
                                    Updating
                                </Button> :
                                <Button type="primary" htmlType="submit" >
                                    Update
                                </Button>
                        }
                    </Form.Item> : <></>
                }

            </Form >
        </>
    );
};
export default AddEditForm;