import { RenderCreated, RenderUpdated, getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import AddEditForm from "Components/Forms/Master/Services/AddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { FileOutlined } from '@ant-design/icons';
import { Link, Outlet, useParams } from "react-router-dom";

const apiUrl = "/plan/rest/services";
const headerTitle = "Services";
const editPermission = "services:edit";
const deletePermission = "services:delete";
const addPermission = "services:add";

const columns = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Priority',
        dataIndex: 'priority',
        key: 'priority',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Parent service',
        dataIndex: 'parent_service_title',
        key: 'parent_service_title',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Created',
        dataIndex: 'created_at',
        key: 'created',
        render: (text, row, index) => {
            return <RenderCreated text={text} row={row} index={index} />
        },
    },
    {
        title: 'Updated',
        dataIndex: 'updated_at',
        key: 'updated',
        render: (text, row, index) => {
            return <RenderUpdated text={text} row={row} index={index} />
        },
    },
    {
        title: 'Set Document Types',
        dataIndex: 'service_document_type',
        key: 'service_document_type',
        render: (text, row, index) => {
            return (
                <Link to={`${row.id}`}>
                    <Button
                        type="default"
                        shape="default"
                        icon={< FileOutlined size={18} />}
                        size="small"
                        style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 5 }}
                    >
                        Document Types
                    </Button>
                </Link>
            )
        },
    },
    ...getCommonColumns({ apiUrl, deletePermission, editPermission, created: false, updated: false, action: true })
]

const ServicesPage = () => {

    const { serviceId } = useParams();
    const userPermissions = useSelector(store => store.auth?.user?.permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    if (serviceId) {
        return <Outlet />
    }

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default ServicesPage;