import { Breadcrumb } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';

function isUUID(string) {
    const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/i;

    return uuidRegex.test(string);
}

const CustomBreadCrumb = () => {

    const [currentBreadCrumbConfig, setCurrentBreadCrumbConfig] = useState([
        {
            title: 'Home',
        },
    ]);

    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        const generateBreadCrumbConfig = async () => {
            console.log({ pathname });
            const allPaths = pathname.split("/");
            console.log(allPaths);
            let potentialConfig = allPaths.filter(a => !!a);
            potentialConfig = potentialConfig.map(a => ({ title: a }))
            console.log({potentialConfig});
            potentialConfig.unshift(currentBreadCrumbConfig[0]);

            const modifiedPotentialConfig = [];
            for (let index = 0; index < potentialConfig.length; index++){
                const pc = potentialConfig[index];
                console.log({ pc, index })
                if (isUUID(pc.title)) {
                    try {
                        const data = { 
                            entity: potentialConfig[index - 1].title?.toLowerCase?.(), 
                            id: pc.title 
                        }
                        const response = await axios.post("/utilities/bb/", data);
                        if (response.data?.title){
                            modifiedPotentialConfig.push({title: response.data.title});
                        } else {
                            throw new Error();
                        }
                    } catch (err) {
                        modifiedPotentialConfig.push(pc);
                    }
                } else {
                    pc.title = pc.title[0]?.toUpperCase?.() + pc.title.slice(1);
                    modifiedPotentialConfig.push(pc);
                }
            }
            setCurrentBreadCrumbConfig(modifiedPotentialConfig);
        }
        generateBreadCrumbConfig();
    }, [location.key]);

    return <Breadcrumb
        style={{ padding: 10 }}
        separator=">"
        items={currentBreadCrumbConfig}
    >
    </Breadcrumb>
}

export default CustomBreadCrumb;