// Import Day.js and the timezone plugin
import { extend, tz } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Extend Day.js with the timezone plugin
extend(utc);
extend(timezone);

// Set the default timezone at the root level
tz.setDefault('GMT'); // Set your desired timezone here