import { useCallback, useEffect } from "react";
import styles from "./LoginPage.module.css";
import { Flex, Typography, Button, Checkbox, Form, Input, notification, Image } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { loginUserAsync } from "Redux/AuthReducer/authSlices";
import { asyncStatuses } from "Redux/enums";
import { LoadingOutlined } from "@ant-design/icons"
import dispatchLogoutAction from "Redux/logoutAction";
import { skvWhiteLogo } from "Assets";

const { Title, Text } = Typography;

const LoginPage = () => {
    const dispatch = useDispatch();
    const [notificationApi, contextHolder] = notification.useNotification();

    const loginRequestStatus = useSelector(store => store.auth.status);
    let loginRequestMsg = useSelector(store => store.auth.errorMsg);

    const openNotification = (message, description, type) => {
        notificationApi[type]({
            message: message,
            description: description,
            placement: "top"
        });
    };

    useEffect(() => {
        if (loginRequestMsg) {
            openNotification("Login Failed", loginRequestMsg, "error");
            dispatchLogoutAction();
        }
    }, [loginRequestStatus]);

    const onFinish = useCallback((values) => {
        // console.log(values);
        dispatch(loginUserAsync(values));
    }, [dispatch]);

    const onFinishFailed = useCallback((errorInfo) => {
        console.log('Failed:', errorInfo);
    }, []);

    return (
        <>
            {contextHolder}
            <Flex align="center" className={styles.container}>
                <div className={styles.leftImageSection}>
                    <Image src={skvWhiteLogo} preview={false} style={{ maxWidth: 200 }} />
                </div>
                <Flex justify="center" align="center" className={styles.rightFormSection}>
                    <Flex gap={"middle"} vertical className={styles.rightLoginFormContainer}>
                        <div>
                            <Title> Login </Title>
                            <Text> Welcome back, please login to your account</Text>
                        </div>
                        <Form
                            name="basic"
                            labelCol={{
                                span: 8,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            layout="vertical"
                        >
                            <Form.Item
                                label="Username"
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your username!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item>
                                <Flex justify="space-between">
                                    <Form.Item name="remember" valuePropName="checked" noStyle>
                                        <Checkbox>Remember me</Checkbox>
                                    </Form.Item>

                                    {/* <a className="login-form-forgot" href="/">
                                        Forgot password
                                    </a> */}
                                </Flex>
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    disabled={loginRequestStatus === asyncStatuses.LOADING ? true : false}
                                    type="primary"
                                    htmlType="submit"
                                    style={{ width: "100%" }}
                                >
                                    {
                                        loginRequestStatus === asyncStatuses.LOADING ? <>
                                            <LoadingOutlined /> Signing you in
                                        </> : <>
                                            Sign in
                                        </>
                                    }
                                </Button>
                            </Form.Item>
                        </Form>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
}

export default LoginPage;