import { combineReducers } from "@reduxjs/toolkit";
import utilitiesReducer from "./UtilitiesReducer/utilitySlice";
import authReducer from "./AuthReducer/authSlices";
// import citiesReducer from "./MasterReducer/citiesSlices";
// import genresReducer from "./MasterReducer/genresSlices";
import masterReducer from "./MasterReducer/crudSlices"

import storage from 'redux-persist/lib/storage'
import { persistReducer } from "redux-persist";

const authPersistConfig = {
    key: 'auth',
    storage: storage,
    blacklist: ['status', 'errorMsg']
}

const utilitiesPersistConfig = {
    key: 'utilities',
    storage: storage,
}

const rootReducer = combineReducers({
    utilities: persistReducer(utilitiesPersistConfig, utilitiesReducer),
    auth: persistReducer(authPersistConfig, authReducer),
    master: masterReducer,
});

export default rootReducer;