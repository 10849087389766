import { createSlice } from "@reduxjs/toolkit";
import { ThemeEnum } from "Redux/enums";

const initialState = {
    theme: ThemeEnum.LIGHT,
    currentHeaderTitle: ""
}

const utilitySlices = createSlice({
    name: "utilities",
    initialState: initialState,
    reducers: {
        switchTheme(state, action) {
            if (state.theme === ThemeEnum.LIGHT) {
                state.theme = ThemeEnum.DARK;
            } else if (state.theme === ThemeEnum.DARK) {
                state.theme = ThemeEnum.LIGHT;
            }
        },
        changeHeaderTitle(state, action) {
            const { payload } = action;
            state.currentHeaderTitle = payload;
        },
        resetUtilitySlice: (state, action) => {
            return initialState
        },
    }
});

export const { switchTheme, changeHeaderTitle, resetUtilitySlice } = utilitySlices.actions;

export default utilitySlices.reducer;