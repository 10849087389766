import { resetAuthSlice } from "./AuthReducer/authSlices";
import { resetMasterSlice } from "./MasterReducer/crudSlices";
import { resetUtilitySlice } from "./UtilitiesReducer/utilitySlice";
import store from "./store";

const logoutAction = () => dispatch => {
    dispatch(resetAuthSlice());
    dispatch(resetMasterSlice());
    dispatch(resetUtilitySlice());
}

const dispachLogoutAction = () => {
    store.dispatch(logoutAction());
}

export default dispachLogoutAction;