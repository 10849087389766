import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from "redux-persist";
import rootReducer from "./rootReducer";
import { storeSizeLoggerMiddleware } from "./reduxMiddleware";

const LS_KEY = 'root_skvbusiness'; // key for the localStorage key

const persistConfig = {
    key: LS_KEY, 
    storage,
    blacklist:['auth', 'utilities', 'master', 'hallLayout', 'theatre', 'program', 'shows']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(storeSizeLoggerMiddleware),
    devTools: process.env.NODE_ENV !== "production" ? true : false
});

export const persistor = persistStore(store); // used to create a persisted store

export default store;